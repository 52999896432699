import { Fragment, useEffect, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { MdCancel } from "react-icons/md";
import { FaTurkishLiraSign, FaDollarSign, FaEuroSign, FaYenSign } from 'react-icons/fa6';
import { Dropdown } from '../ComponentExport';
import { IoLanguage } from "react-icons/io5";
import { FaMoneyBillWaveAlt } from "react-icons/fa";
import { FaInstagram, FaFacebook, FaYoutube, FaTwitter } from "react-icons/fa";
import useStoreContext from '../../store/useStoreContext';
import { PiHandCoinsBold } from "react-icons/pi";

const products = [
    { name: 'Analytics', description: 'Get a better understanding of your traffic', href: '#', icon: "ChartPieIcon" },
    { name: 'Engagement', description: 'Speak directly to your customers', href: '#', icon: "CursorArrowRaysIcon" },
    { name: 'Security', description: 'Your customers’ data will be safe and secure', href: '#', icon: "FingerPrintIcon" },
    { name: 'Integrations', description: 'Connect with third-party tools', href: '#', icon: "SquaresPlusIcon" },
    { name: 'Automations', description: 'Build strategic funnels that will convert', href: '#', icon: "ArrowPathIcon" },
]
const callsToAction = [
    { name: 'Watch demo', href: '#', icon: "PlayCircleIcon" },
    { name: 'Contact sales', href: '#', icon: "PhoneIcon" },
]


export default function Example() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const { store, updateStore } = useStoreContext();

    useEffect(() => {
        updateStore({ mobileMenuShow: false })
    }, []);


    const currencyMenu = [
        {
            id: 0,
            name: 'TRY',
            icon: <FaTurkishLiraSign />,
        },
        {
            id: 1,
            name: 'USD',
            icon: <FaDollarSign />,
        },
        {
            id: 2,
            name: 'EUR',
            icon: <FaEuroSign />,
        },
        {
            id: 4,
            name: 'JPY',
            icon: <FaYenSign />,
        },
    ];

    const langMenu = [
        {
            id: 0,
            name: 'TR',
            img: "https://flagsapi.com/TR/flat/64.png",
        },
        {
            id: 1,
            name: 'EN',
            img: "https://flagsapi.com/GB/flat/64.png",
        },
        {
            id: 2,
            name: 'FR',
            img: "https://flagsapi.com/FR/flat/64.png",
        },
        {
            id: 3,
            name: 'DE',
            img: "https://flagsapi.com/DE/flat/64.png",
        }
    ];
    return (
        <header className="bg-white z-[200]">
            <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div className="flex lg:flex-1">
                    <a href="#" className="-m-1.5 p-1.5">
                        <span className="sr-only">Your Company</span>
                        <img className="h-8 w-auto" src="https://raw.githubusercontent.com/batuhankaan/beevrocom/master/src/assets/images/beevro_black.png?token=GHSAT0AAAAAACLO4I2EVHRSGJ7RJKBH55FKZLXCODQ" alt="" />
                    </a>
                </div>
                {/* <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => updateStore({ mobileMenuShow: true })
                        }
                    >
                        <span className="sr-only">Open main menu</span>
                        <HiOutlineMenuAlt3 size={25} />
                    </button>
                </div> */}
                <div className="flex gap-3">
                    <Dropdown icon={<PiHandCoinsBold className="text-primary" size={20}/>} title="TL" menu={currencyMenu} />
                    <Dropdown icon={<IoLanguage className="text-primary" size={20}/>} title="TR" menu={langMenu} />
                </div>
                <Popover.Group className="hidden lg:flex lg:gap-x-12">
                    <Popover className="relative">
                        <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                            Menu 1
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                                <div className="p-4">
                                    {products.map((item) => (
                                        <div
                                            key={item.name}
                                            className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                                        >
                                            <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                                            </div>
                                            <div className="flex-auto">
                                                <a href={item.href} className="block font-semibold text-gray-900">
                                                    {item.name}
                                                    <span className="absolute inset-0" />
                                                </a>
                                                <p className="mt-1 text-gray-600">{item.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                                    {callsToAction.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                                        >
                                            <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </Popover>

                    <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                        Menu 2
                    </a>
                    <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                        Menu 3
                    </a>
                    <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                        Menu 4
                    </a>
                </Popover.Group>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                        Log in <span aria-hidden="true">&rarr;</span>
                    </a>
                </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={store.mobileMenuShow} onClose={() => updateStore({ mobileMenuShow: false })
            }>
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img
                                className="h-8 w-auto"
                                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                                alt=""
                            />
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => updateStore({ mobileMenuShow: false })}
                        >
                            <span className="sr-only">Close menu</span>
                            <MdCancel size={25} />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                <Disclosure as="div" className="-mx-3">
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                            Menu 1
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="mt-2 space-y-2">
                                                {[...products, ...callsToAction].map((item) => (
                                                    <Disclosure.Button
                                                        key={item.name}
                                                        as="a"
                                                        href={item.href}
                                                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                                    >
                                                        {item.name}
                                                    </Disclosure.Button>
                                                ))}
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                                <a
                                    href="#"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Menu 2
                                </a>
                                <a
                                    href="#"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Menu 3
                                </a>
                                <a
                                    href="#"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Anket
                                </a>
                            </div>
                            <div className="py-6">
                                <div>
                                    <span className="font-medium">Sosyal Medya Hesaplarımız</span>
                                    <div className="flex gap-3">
                                        <FaInstagram size={25} />
                                        <FaFacebook size={25} />
                                        <FaYoutube size={25} />
                                        <FaTwitter size={25} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    )
}

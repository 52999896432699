import { Carousel, Header, TabMenu } from '../components/ComponentExport'
import { FiFilter } from "react-icons/fi";
import { GoFilter } from "react-icons/go";
import { FaList } from "react-icons/fa6";
import { PiSquaresFour } from "react-icons/pi";
import { FaSearch } from "react-icons/fa";

import useStoreContext from '../store/useStoreContext';

const Home = () => {
    const { store, updateStore } = useStoreContext();

    return (
        <div className="container mx-auto sm:px-6 lg:px-8 bg-white h-full">
            <Header />

            <div>
                <div className="flex gap-4 bg-black p-3 items-center justify-between rounded-[16px]">
                    <div className="border-primary/50 w-full border-[1px] rounded-[16px] flex p-3">
                        <input className="w-full bg-transparent rounded-full indent-4 h-full text-white" type='text' placeholder='Ara...' />
                        <FaSearch size={25} className="text-white" />

                    </div>
                    <div className="flex gap-4">
                        <button className=''>
                            <FiFilter size={25} className="text-white" />
                        </button>
                        <button className=''>
                            <GoFilter size={25} className="text-white" />
                        </button>
                        <button onClick={() => { updateStore({ list: !store.list }) }} className=''>
                            {store.list ? <PiSquaresFour size={25} className="text-white" /> : <FaList size={25} className="text-white" />}
                        </button>
                    </div>
                </div>
                <div>
                    <div className="text-2xl font-medium">
                        En Çok Tercih Edilenler
                    </div>
                    <Carousel />
                </div>
            </div>
            <div className="mt-3">
                <TabMenu />

            </div>
        </div>
    )
}

export default Home
import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import useStoreContext from '../../store/useStoreContext'

function Product1({ product }) {
    
    const { store, updateStore } = useStoreContext();
    useEffect(() => {
        console.log(store)
    },[store]);

    return (
        <div onClick={() => { updateStore({modalShow: true, modalContent:"product", product: product})}} key={product.id}>
            <div className="group relative border-b border-r border-gray-200 p-4 sm:p-6">
                <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-200 group-hover:opacity-75">
                    <Swiper
                        slidesPerView={1}
                        pagination
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        loop={true}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        {product.images.map((image) => (
                            <SwiperSlide>
                                <img
                                    src={image.imageSrc}
                                    alt={image.imageAlt}
                                    className="h-full w-full object-cover object-center"
                                />
                            </SwiperSlide>
                        ))}

                    </Swiper>
                </div>
                <div className="pb-4 pt-10 text-center">
                    <h3 className="text-sm font-medium text-gray-900">
                        <a href={product.href}>
                            <span aria-hidden="true" className="absolute inset-0" />
                            {product.name}
                        </a>
                    </h3>
                    <div className="mt-3 flex flex-col items-center">
                        <p className="sr-only">{product.rating} out of 5 stars</p>
                        <p className="mt-1 text-sm text-gray-500">{product.reviewCount} reviews</p>
                    </div>
                    <p className="mt-4 text-base font-medium text-gray-900">{product.price}</p>
                </div>
            </div>
        </div>
    )
}

export default Product1
import React, { useState } from 'react'
import { IoSettings } from "react-icons/io5";
import { Modal, Dropdown } from '../ComponentExport';


import { IoHome } from "react-icons/io5";
import { HiShoppingCart } from "react-icons/hi2";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import useStoreContext from '../../store/useStoreContext';

const BottomBar = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { store, updateStore } = useStoreContext();

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="fixed bottom-0 left-0 z-20 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
            <div className="grid h-full max-w-lg grid-cols-3 mx-auto font-medium">
                <button onClick={() => updateStore({ mobileMenuShow: true })} type="button" className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
                    <HiOutlineMenuAlt3 className="w-5 h-5 mb-2 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" size={25} />
                    <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500">Menu</span>
                </button>
                <button type="button" className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
                    <IoHome className="w-5 h-5 mb-2 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" size={25} />
                    <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500">Anasayfa</span>
                </button>
                <button onClick={() => updateStore({ modalShow: true, modalContent: "cart" })} type="button" className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
                    <div className="relative">
                        <HiShoppingCart className="w-5 h-5 mb-2 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" size={25} />
                        <div className="bg-red-600 absolute -right-3 -top-2 w-5 h-5 rounded-full text-center text-xs text-white">
                            1
                        </div>
                    </div>
                    <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500 relative">
                        Sepet
                    </span>
                </button>
            </div>
        </div>
    )
}

export default BottomBar
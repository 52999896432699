import React from 'react'
import useStoreContext from '../../../store/useStoreContext'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { OptionController, Count } from '../../ComponentExport';

const ModalProductDetail = () => {
    const { store, updateStore } = useStoreContext();

    return (
        <>
            <div className="">
                <Swiper
                    slidesPerView={1}
                    pagination
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    loop={true}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                >
                    {store.product?.images.map((image) => (
                        <SwiperSlide>
                            <img
                                src={image.imageSrc}
                                alt={image.imageAlt}
                                className={`${
                                    store.modalSize === "full" ? "h-full w-full object-cover object-center" : "m-auto object-center h-[250px]"
                                  }`}
                                  
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="flex justify-center">
                    <div className="w-12 h-1 bg-gray-300 rounded"></div>
                </div>


                <div className="p-4">
                    <div className="flex justify-between items-center">
                        <div className="mt-4">
                            <p className="font-medium">{store.product?.name}</p>
                            <p className="text-gray-500 font-medium">{store.product?.price} s</p>
                        </div>
                        <Link className="bg-red-400 text-white p-4" to="/ar">
                            Canlı Önizle
                        </Link>
                    </div>


                    <OptionController options={store.product?.option} />
                </div>
            </div>
            {/* {(modalState === 'half' || modalState === 'full') && (
                <div className="bg-gray-300 items-center justify-around absolute flex w-full bottom-0">
                    {modalState === "half" && <Count />}
                    <button className='p-4 font-medium'>Sepete Ekle</button>
                </div>
            )} */}
        </>
    )
}

export default ModalProductDetail
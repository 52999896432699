import React, { useState, useRef, useEffect } from 'react';
import 'tailwindcss/tailwind.css';
import useStoreContext from '../../store/useStoreContext';
import 'swiper/css/pagination';
import modalContents from './ModalContents';

const SwipeModal = () => {
  const { store, updateStore } = useStoreContext();

  const [modalState, setModalState] = useState('closed');
  const ref = useRef(null);

  let touchStartY = null;

  useEffect(() => {
    if (modalState != "closed") {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [modalState]);

  const handleTouchMove = (e) => {
    if (!touchStartY) return;

    const touchEndY = e.changedTouches[0].clientY;
    const deltaY = touchEndY - touchStartY;
    const sensitivity = 15;

    if (deltaY > sensitivity) {
      if (modalState === 'full') {
        setModalState('half');
        updateStore({modalSize: 'half'});
      } else if (modalState === 'half') {
        setModalState('closed');
        updateStore({ modalShow: false });
      }
    } else if (deltaY < -sensitivity) {
      if (modalState === 'half') {
        setModalState('full');
        updateStore({modalSize: 'full'});
      } else if (modalState === 'closed') {
        setModalState('half');
        updateStore({modalSize: 'half'});
      }
    }

    touchStartY = touchEndY;
  };

  useEffect(() => {
    const modal = ref.current;
    if (modal) {
      modal.addEventListener('touchstart', handleTouchStart);
      modal.addEventListener('touchmove', handleTouchMove);
    }

    return () => {
      if (modal) {
        modal.removeEventListener('touchstart', handleTouchStart);
        modal.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, [modalState]);

  useEffect(() => {
    if (store.modalShow) {
      setModalState('half');
    }
  }, [store.modalShow]);

  const handleTouchStart = (e) => {
    touchStartY = e.touches[0].clientY;
  };

  const getHeightClass = () => {
    switch (modalState) {
      case 'full':
        return 'h-full';
      case 'half':
        return 'h-1/2';
      case 'closed':
        return 'h-0';
      default:
        return 'h-1/2';
    }
  };

  return (
    <div ref={ref} className={`fixed inset-x-0 bottom-0 z-30 rounded-t-[20px] bg-slate-300 ${getHeightClass()} transition-height duration-300 shadow-2xl`}>
      {modalContents[store.modalContent]}
    </div>
  );
};

export default SwipeModal;

import React from 'react';
import { Menu, Transition } from '@headlessui/react';

const Dropdown = ({menu, title, icon}) => {
    return (
        <div className="text-right">
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className="inline-flex items-center gap-2 w-full justify-center rounded-md bg-black px-4 py-2 text-sm font-medium text-white hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                        {icon}
                        {title}
                    </Menu.Button>
                </div>
                <Transition
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    {(open) =>
                        open && (
                            <div className="absolute z-50 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                <div className="px-1 py-1">
                                    <Menu.Items>
                                        {menu.map((currency) => (
                                            <Menu.Item key={currency.id}>
                                                {({ active }) => (
                                                    <div className={`flex items-center ${active ? 'bg-gray-100' : ''}`}>
                                                        {currency.icon ? currency.icon : <img src={currency.img} alt={currency.name} />}
                                                        <span className={`ml-2 ${active ? 'text-gray-900' : 'text-gray-700'}`}>{currency.name}</span>
                                                    </div>
                                                )}
                                            </Menu.Item>
                                        ))}
                                    </Menu.Items>
                                </div>
                            </div>
                        )
                    }
                </Transition>
            </Menu>
        </div>
    );
};

export default Dropdown;

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Temel Swiper stilini içerir
import 'swiper/css/navigation'; // Eğer navigasyon gerekiyorsa
import 'swiper/css/pagination'; // Eğer pagination gerekiyorsa


const Carousel = () => {
  return (
    <Swiper
    spaceBetween={20}
    slidesPerView={1.4}
    loop={true}
    centeredSlides={true}
    onSlideChange={() => console.log('slide change')} 
    onSwiper={(swiper) => console.log(swiper)} 
  >
    <SwiperSlide className="bg-red-500 h-20 flex justify-center items-center">
      Slide 1
    </SwiperSlide>
    <SwiperSlide className="bg-green-500 h-20 flex justify-center items-center">
      Slide 2
    </SwiperSlide>
    <SwiperSlide className="bg-blue-500 h-20 flex justify-center items-center">
      Slide 3
    </SwiperSlide>
    <SwiperSlide className="bg-yellow-500 h-20 flex justify-center items-center">
      Slide 4
    </SwiperSlide>
    <SwiperSlide className="bg-red-500 h-20 flex justify-center items-center">
      Slide 5
    </SwiperSlide>
    <SwiperSlide className="bg-green-500 h-20 flex justify-center items-center">
      Slide 6
    </SwiperSlide>
    <SwiperSlide className="bg-blue-500 h-20 flex justify-center items-center">
      Slide 7
    </SwiperSlide>
    <SwiperSlide className="bg-yellow-500 h-20 flex justify-center items-center">
      Slide 8
    </SwiperSlide>
  </Swiper>
  
  
  );
};

export default Carousel;

import React, { useState, useRef, useEffect } from 'react';
import { Product1, ProductList } from '../ComponentExport';
import useStoreContext from '../../store/useStoreContext';

function TabMenu() {
    const { store, updateStore } = useStoreContext();
    let [categories] = useState({
        İçecekler: [
            {
                id: 1,
                name: 'İçecek Menü',
                price: '200 TL',
                rating: 5,
                reviewCount: 38,
                images: [
                    { imageSrc: 'https://i.pinimg.com/736x/d1/6b/7a/d16b7a2553252183b8f49aaa2336fca0.jpg', imageAlt: 'Gamer Menü' },
                    { imageSrc: 'https://i.pinimg.com/736x/d1/6b/7a/d16b7a2553252183b8f49aaa2336fca0.jpg', imageAlt: 'Gamer Menü' },
                    { imageSrc: 'https://i.pinimg.com/736x/d1/6b/7a/d16b7a2553252183b8f49aaa2336fca0.jpg', imageAlt: 'Gamer Menü' },
                ],
                imageAlt: 'TODO',
                href: '#',
                option: [
                    {
                        id: 0,
                        name: 'Size',
                        type: 'radio',
                        options: [
                            { id: 0, name: 'XS', value: 'xs' },
                            { id: 1, name: 'S', value: 's' },
                            { id: 2, name: 'M', value: 'm' },
                            { id: 3, name: 'L', value: 'l' },
                            { id: 4, name: 'XL', value: 'xl' },
                        ],
                    },
                    {
                        id: 1,
                        name: 'Color',
                        type: 'checkbox',
                        options: [
                            { id: 0, name: 'Red', value: 'red' },
                            { id: 1, name: 'Green', value: 'green' },
                            { id: 2, name: 'Blue', value: 'blue' },
                        ],
                    }
                ],
            },
            {
                id: 1,
                name: 'İçecek Menü',
                price: '200 TL',
                rating: 5,
                reviewCount: 38,
                images: [
                    { imageSrc: 'https://i.pinimg.com/736x/d1/6b/7a/d16b7a2553252183b8f49aaa2336fca0.jpg', imageAlt: 'Gamer Menü' },
                    { imageSrc: 'https://i.pinimg.com/736x/d1/6b/7a/d16b7a2553252183b8f49aaa2336fca0.jpg', imageAlt: 'Gamer Menü' },
                    { imageSrc: 'https://i.pinimg.com/736x/d1/6b/7a/d16b7a2553252183b8f49aaa2336fca0.jpg', imageAlt: 'Gamer Menü' },
                ],
                imageAlt: 'TODO',
                href: '#',
                option: [
                    {
                        id: 0,
                        name: 'Size',
                        type: 'radio',
                        options: [
                            { id: 0, name: 'XS', value: 'xs' },
                            { id: 1, name: 'S', value: 's' },
                            { id: 2, name: 'M', value: 'm' },
                            { id: 3, name: 'L', value: 'l' },
                            { id: 4, name: 'XL', value: 'xl' },
                        ],
                    },
                    {
                        id: 1,
                        name: 'Color',
                        type: 'checkbox',
                        options: [
                            { id: 0, name: 'Red', value: 'red' },
                            { id: 1, name: 'Green', value: 'green' },
                            { id: 2, name: 'Blue', value: 'blue' },
                        ],
                    }
                ],
            },
            {
                id: 1,
                name: 'İçecek Menü',
                price: '200 TL',
                rating: 5,
                reviewCount: 38,
                images: [
                    { imageSrc: 'https://i.pinimg.com/736x/d1/6b/7a/d16b7a2553252183b8f49aaa2336fca0.jpg', imageAlt: 'Gamer Menü' },
                    { imageSrc: 'https://i.pinimg.com/736x/d1/6b/7a/d16b7a2553252183b8f49aaa2336fca0.jpg', imageAlt: 'Gamer Menü' },
                    { imageSrc: 'https://i.pinimg.com/736x/d1/6b/7a/d16b7a2553252183b8f49aaa2336fca0.jpg', imageAlt: 'Gamer Menü' },
                ],
                imageAlt: 'TODO',
                href: '#',
                option: [
                    {
                        id: 0,
                        name: 'Size',
                        type: 'radio',
                        options: [
                            { id: 0, name: 'XS', value: 'xs' },
                            { id: 1, name: 'S', value: 's' },
                            { id: 2, name: 'M', value: 'm' },
                            { id: 3, name: 'L', value: 'l' },
                            { id: 4, name: 'XL', value: 'xl' },
                        ],
                    },
                    {
                        id: 1,
                        name: 'Color',
                        type: 'checkbox',
                        options: [
                            { id: 0, name: 'Red', value: 'red' },
                            { id: 1, name: 'Green', value: 'green' },
                            { id: 2, name: 'Blue', value: 'blue' },
                        ],
                    }
                ],
            },
            {
                id: 1,
                name: 'İçecek Menü',
                price: '200 TL',
                rating: 5,
                reviewCount: 38,
                images: [
                    { imageSrc: 'https://i.pinimg.com/736x/d1/6b/7a/d16b7a2553252183b8f49aaa2336fca0.jpg', imageAlt: 'Gamer Menü' },
                    { imageSrc: 'https://i.pinimg.com/736x/d1/6b/7a/d16b7a2553252183b8f49aaa2336fca0.jpg', imageAlt: 'Gamer Menü' },
                    { imageSrc: 'https://i.pinimg.com/736x/d1/6b/7a/d16b7a2553252183b8f49aaa2336fca0.jpg', imageAlt: 'Gamer Menü' },
                ],
                imageAlt: 'TODO',
                href: '#',
                option: [
                    {
                        id: 0,
                        name: 'Size',
                        type: 'radio',
                        options: [
                            { id: 0, name: 'XS', value: 'xs' },
                            { id: 1, name: 'S', value: 's' },
                            { id: 2, name: 'M', value: 'm' },
                            { id: 3, name: 'L', value: 'l' },
                            { id: 4, name: 'XL', value: 'xl' },
                        ],
                    },
                    {
                        id: 1,
                        name: 'Color',
                        type: 'checkbox',
                        options: [
                            { id: 0, name: 'Red', value: 'red' },
                            { id: 1, name: 'Green', value: 'green' },
                            { id: 2, name: 'Blue', value: 'blue' },
                        ],
                    }
                ],
            },
            {
                id: 1,
                name: 'İçecek Menü',
                price: '200 TL',
                rating: 5,
                reviewCount: 38,
                images: [
                    { imageSrc: 'https://i.pinimg.com/736x/d1/6b/7a/d16b7a2553252183b8f49aaa2336fca0.jpg', imageAlt: 'Gamer Menü' },
                    { imageSrc: 'https://i.pinimg.com/736x/d1/6b/7a/d16b7a2553252183b8f49aaa2336fca0.jpg', imageAlt: 'Gamer Menü' },
                    { imageSrc: 'https://i.pinimg.com/736x/d1/6b/7a/d16b7a2553252183b8f49aaa2336fca0.jpg', imageAlt: 'Gamer Menü' },
                ],
                imageAlt: 'TODO',
                href: '#',
                option: [
                    {
                        id: 0,
                        name: 'Size',
                        type: 'radio',
                        options: [
                            { id: 0, name: 'XS', value: 'xs' },
                            { id: 1, name: 'S', value: 's' },
                            { id: 2, name: 'M', value: 'm' },
                            { id: 3, name: 'L', value: 'l' },
                            { id: 4, name: 'XL', value: 'xl' },
                        ],
                    },
                    {
                        id: 1,
                        name: 'Color',
                        type: 'checkbox',
                        options: [
                            { id: 0, name: 'Red', value: 'red' },
                            { id: 1, name: 'Green', value: 'green' },
                            { id: 2, name: 'Blue', value: 'blue' },
                        ],
                    }
                ],
            },
            {
                id: 1,
                name: 'İçecek Menü',
                price: '200 TL',
                rating: 5,
                reviewCount: 38,
                images: [
                    { imageSrc: 'https://i.pinimg.com/736x/d1/6b/7a/d16b7a2553252183b8f49aaa2336fca0.jpg', imageAlt: 'Gamer Menü' },
                    { imageSrc: 'https://i.pinimg.com/736x/d1/6b/7a/d16b7a2553252183b8f49aaa2336fca0.jpg', imageAlt: 'Gamer Menü' },
                    { imageSrc: 'https://i.pinimg.com/736x/d1/6b/7a/d16b7a2553252183b8f49aaa2336fca0.jpg', imageAlt: 'Gamer Menü' },
                ],
                imageAlt: 'TODO',
                href: '#',
                option: [
                    {
                        id: 0,
                        name: 'Size',
                        type: 'radio',
                        options: [
                            { id: 0, name: 'XS', value: 'xs' },
                            { id: 1, name: 'S', value: 's' },
                            { id: 2, name: 'M', value: 'm' },
                            { id: 3, name: 'L', value: 'l' },
                            { id: 4, name: 'XL', value: 'xl' },
                        ],
                    },
                    {
                        id: 1,
                        name: 'Color',
                        type: 'checkbox',
                        options: [
                            { id: 0, name: 'Red', value: 'red' },
                            { id: 1, name: 'Green', value: 'green' },
                            { id: 2, name: 'Blue', value: 'blue' },
                        ],
                    }
                ],
            }
        ],
        Yemek: [
            {
                id: 1,
                name: 'Yemek Menü',
                price: '200 TL',
                rating: 5,
                reviewCount: 38,
                images: [
                    { imageSrc: 'https://images.deliveryhero.io/image/fd-tr/Products/10770256.jpg?width=150&height=150', imageAlt: 'Gamer Menü' },
                    { imageSrc: 'https://images.deliveryhero.io/image/fd-tr/Products/10770256.jpg?width=150&height=150', imageAlt: 'Gamer Menü' },
                    { imageSrc: 'https://images.deliveryhero.io/image/fd-tr/Products/10770256.jpg?width=150&height=150', imageAlt: 'Gamer Menü' },
                    { imageSrc: 'https://images.deliveryhero.io/image/fd-tr/Products/10770256.jpg?width=150&height=150', imageAlt: 'Gamer Menü' },
                    { imageSrc: 'https://images.deliveryhero.io/image/fd-tr/Products/10770256.jpg?width=150&height=150', imageAlt: 'Gamer Menü' },
                ],
                imageAlt: 'TODO',
                href: '#',
                option: [
                    {
                        id: 0,
                        name: 'Size',
                        type: 'radio',
                        options: [
                            { id: 0, name: 'XS', value: 'xs' },
                            { id: 1, name: 'S', value: 's' },
                            { id: 2, name: 'M', value: 'm' },
                            { id: 3, name: 'L', value: 'l' },
                            { id: 4, name: 'XL', value: 'xl' },
                        ],
                    },
                    {
                        id: 1,
                        name: 'Color',
                        type: 'checkbox',
                        options: [
                            { id: 0, name: 'Red', value: 'red' },
                            { id: 1, name: 'Green', value: 'green' },
                            { id: 2, name: 'Blue', value: 'blue' },
                        ],
                    }
                ],
            }
        ],
        Kahve: [
            {
                id: 1,
                name: 'Kahve Menü',
                price: '200 TL',
                rating: 5,
                reviewCount: 38,
                images: [
                    { imageSrc: 'https://images.deliveryhero.io/image/fd-tr/Products/10770256.jpg?width=150&height=150', imageAlt: 'Gamer Menü' },
                    { imageSrc: 'https://images.deliveryhero.io/image/fd-tr/Products/10770256.jpg?width=150&height=150', imageAlt: 'Gamer Menü' },
                    { imageSrc: 'https://images.deliveryhero.io/image/fd-tr/Products/10770256.jpg?width=150&height=150', imageAlt: 'Gamer Menü' },
                    { imageSrc: 'https://images.deliveryhero.io/image/fd-tr/Products/10770256.jpg?width=150&height=150', imageAlt: 'Gamer Menü' },
                    { imageSrc: 'https://images.deliveryhero.io/image/fd-tr/Products/10770256.jpg?width=150&height=150', imageAlt: 'Gamer Menü' },
                ],
                imageAlt: 'TODO',
                href: '#',
                option: [
                    {
                        id: 0,
                        name: 'Size',
                        type: 'radio',
                        options: [
                            { id: 0, name: 'XS', value: 'xs' },
                            { id: 1, name: 'S', value: 's' },
                            { id: 2, name: 'M', value: 'm' },
                            { id: 3, name: 'L', value: 'l' },
                            { id: 4, name: 'XL', value: 'xl' },
                        ],
                    },
                    {
                        id: 1,
                        name: 'Color',
                        type: 'checkbox',
                        options: [
                            { id: 0, name: 'Red', value: 'red' },
                            { id: 1, name: 'Green', value: 'green' },
                            { id: 2, name: 'Blue', value: 'blue' },
                        ],
                    }
                ],
            }
        ],
    })

    const tabNames = Object.keys(categories);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const containerRef = useRef(null);

    const selectTab = (index) => {
        setActiveTabIndex(index);
        scrollToTab(index);
    };

    const scrollToTab = (index) => {
        if (containerRef.current) {
            const tabWidth = containerRef.current.offsetWidth;
            containerRef.current.scrollLeft = index * tabWidth;
        }
    };

    const debounce = (func, wait) => {
        let timeout;
        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    };

    const handleScroll = () => {
        const scrollPosition = containerRef.current.scrollLeft;
        const tabWidth = containerRef.current.offsetWidth;
        const index = Math.round(scrollPosition / tabWidth);

        setActiveTabIndex(index);
        containerRef.current.scrollTo({
            left: tabWidth * index,
            behavior: 'smooth'
        });
    };

    const debouncedHandleScroll = debounce(handleScroll, 100);

    useEffect(() => {
        const container = containerRef.current;
        container.addEventListener('scroll', debouncedHandleScroll);

        return () => container.removeEventListener('scroll', debouncedHandleScroll);
    }, []);

    const updateTabLinePositionOnScroll = () => {
        const tabLine = document.querySelector('.tab-line');
        if (tabLine && containerRef.current) {
            const totalWidth = containerRef.current.scrollWidth;
            const scrollPosition = containerRef.current.scrollLeft;
            const tabWidth = totalWidth / tabNames.length;
            const calculatedLeft = (scrollPosition / totalWidth) * 100;

            tabLine.style.left = `${calculatedLeft}%`;
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', updateTabLinePositionOnScroll);

            return () => container.removeEventListener('scroll', updateTabLinePositionOnScroll);
        }
    }, [tabNames.length]);

    


    return (
        <div className="h-full">
            <div className="flex justify-between">
                <span className="text-2xl font-medium">Kategoriler</span>
                <span className="text-primary font-medium">Hepsini Gör</span>
            </div>
            <div className="flex sticky inset-0 z-30 tab-menu">
                {tabNames.map((tabName, index) => (
                    <button
                        key={tabName}
                        onClick={() => selectTab(index)}
                        className={`py-2 px-4 duration-300 flex flex-col items-center font-medium tab ${activeTabIndex === index ? 'active' : ''}`}
                    >
                        <img className={`rounded-full w-12 border-2`} src="https://picsum.photos/200/200" />
                        {tabName}
                    </button>
                ))}
                {activeTabIndex !== null && (
                    <div className="tab-line" style={{ width: `${100 / tabNames.length}%`, left: `${activeTabIndex * (100 / tabNames.length)}%` }} />
                )}
            </div>
            <div
                className="w-full overflow-hidden overflow-x-auto"
                ref={containerRef}
                style={{ whiteSpace: 'nowrap' }}
            >
                {tabNames.map((tabName, index) => (
                    <div
                        key={tabName}
                        className="inline-block align-top w-full"
                    >
                        {/* <div className="w-full -mx-px grid grid-cols-2 border-l border-gray-200 sm:mx-0 md:grid-cols-3 lg:grid-cols-4"> */}
                        <div className={`w-full -mx-px grid ${store.list ? 'grid-cols-1' : 'grid-cols-2'} border-l border-gray-200 sm:mx-0 md:grid-cols-3 lg:grid-cols-4`}>
                            {categories[tabName].map((product) => (
                                store.list ? (
                                    <ProductList key={product.id} product={product} />
                                ) : (
                                    <Product1 key={product.id} product={product} />
                                )
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TabMenu;

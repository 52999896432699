import React, { useState } from "react";

function OptionController({ options }) {
  const [selectedOptions, setSelectedOptions] = useState({});

  const handleOptionChange = (name, value) => {
    setSelectedOptions({ ...selectedOptions, [name]: value });
  };

  return (
    <div>
      {options?.map((optionGroup, groupIndex) => (
        <div key={groupIndex}>
          <h3>{optionGroup.name}</h3>
          <div className="flex gap-4">
            {optionGroup.options.map((option, optionIndex) => (
              <div className="flex gap-2" key={optionIndex}>
                {optionGroup.type === "radio" && (
                  <input
                    type="radio"
                    id={`option${groupIndex}-${optionIndex}`}
                    name={optionGroup.name}
                    value={option.value}
                    checked={selectedOptions[optionGroup.name] === option.value}
                    onChange={() => handleOptionChange(optionGroup.name, option.value)}
                  />
                )}
                {optionGroup.type === "checkbox" && (
                  <input
                    type="checkbox"
                    id={`option${groupIndex}-${optionIndex}`}
                    name={optionGroup.name}
                    value={option.value}
                    checked={selectedOptions[optionGroup.name] === option.value}
                    onChange={() => handleOptionChange(optionGroup.name, option.value)}
                  />
                )}
                <label htmlFor={`option${groupIndex}-${optionIndex}`}>
                  {option.name}
                </label>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default OptionController;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './pages/Home';
import ProductDetail from './pages/ProductDetail';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import StoreProvider from './store/StoreProvider';
import { BottomBar, SwipeModal } from './components/ComponentExport';
import ARScene from './components/ar/Ar';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <StoreProvider>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/detay' element={<ProductDetail />} />
          <Route path='/ar' element={<ARScene />} />
        </Routes>
        <BottomBar/>
        <SwipeModal />
      </StoreProvider>
    </BrowserRouter>
  </React.StrictMode>
);

import React from 'react';
import ModalProductDetail from './ModalContents/ModalProductDetail'
import ModalCartDetail from './ModalContents/ModalCartDetail'

const modalContents = {
  product: <ModalProductDetail />,
  cart: <ModalCartDetail />,
};

export default modalContents;

import React, { useState } from 'react';
import StoreContext from './StoreContext';

const StoreProvider = ({ children }) => {
  const [store, setStore] = useState({
    modalShow: false,
    modalSize: null,
    mobileMenuShow: false,
    list:false,
  });

  const updateStore = (newState) => {
    setStore({ ...store, ...newState });
  };

  return (
    <StoreContext.Provider value={{ store, updateStore }}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
